import PhoneInput from "react-phone-input-2";
import styled from "styled-components";

export const PhoneNumberCountryStyled = styled(PhoneInput)`
  height: 52px;
  /* font-size: large !important; */
  width: 100%;
  .flag-dropdown svg {
    /* Set tabindex to -1 to disable focus with Tab key */
    tabindex: -1;
    * {
      tabindex: -1;
    }
  }
  .form-control {
    width: 100%;
    font-size: large !important;
    height: 52px;
    border: 2px solid #f5f5f5;
    border-radius: 12px;
    font-size: 14px !important;
    background-color: #f5f5f5;
    padding-top: 2px;
    padding-bottom: 2px;
    &:focus {
      border: 2px solid #0f6fdf;
    }
    &.open {
      border-radius: 12px;
      border: 2px solid #0f6fdf;
    }
  }
  .flag-dropdown {
    border-radius: 12px 0 0 12px;
    background-color: #f5f5f5;
    border: 2px solid #f5f5f5;
    border-right: none !important;

    /* overflow: hidden;   */

    .selected-flag {
      border-radius: 12px 0 0 12px;

      &:hover {
        background-color: inherit !important;
        border-radius: 12px 0 0 12px;
      }

      &.open {
        border-radius: 12px 0 0 12px;
        /* border: 2px solid #0f6fdf; */
        background-color: inherit !important;
      }
    }

    &:focus {
      border-radius: 12px 0 0 12px;
    }

    &.open {
      border: 2px solid #0f6fdf;
      border-radius: 12px 0 0 12px;
      background-color: inherit !important;
    }
  }

  .form-control:focus ~ .flag-dropdown {
    border-top: 2px solid #0f6fdf;
    border-left: 2px solid #0f6fdf;
    border-bottom: 2px solid #0f6fdf;
  }
`;

export const PhoneNumberlabelStyled = styled.div`
  margin-bottom: 5px;
  margin-top: 5px;

  label {
    color: #232323;
    font-weight: 500;
    font-size: 14px;
  }
`;

// export const MaskInputStyled = styled(MaskInput)`
//   border-radius: 8px;
//   border: 1px solid ${({ theme }) => theme.colors.border.grey};
//   box-sizing: border-box;
//   width: 100%;
//   color: ${({ theme }) => theme.colors.text.lightBlack};
//   padding: 16px 15px;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 16px;
//   outline: none;
//   transition: all 300ms ease-out;
//   &:hover {
//     border-color: ${({ theme }) => theme.colors.border.grey};
//   }
//   &:focus {
//     border-color: ${({ theme }) => theme.colors.border.grey};
//   }
//   &::placeholder {
//     color: ${({ theme }) => theme.colors.text.lightBlack};
//   }
//   &:focus {
//     box-shadow: none;
//     color: #8b90a5;
//     border-color: #edf1ff;
//   }
//   &.error {
//     border-color: ${({ theme }) => theme.colors.border.red};
//   }
// `;
