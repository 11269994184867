import styled from "styled-components";
type IStyled = { fullWidth: boolean };
export const CheckboxStyled = styled.div<IStyled>`
  padding: 7.5px 10px;
  background-color: #f5f5f5 !important;
  border-radius: 12px !important;
  color: black !important;
  text-transform: capitalize !important;
  justify-content: flex-start !important;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  display: flex;
  align-items: center;
  label {
    cursor: pointer;
  }
`;
