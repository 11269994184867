import { Select } from "@mui/material";
import styled from "styled-components";

export const SelectStyled = styled(Select)`
  height: 48px;
  .MuiSelect-select {
    background-color: #f5f5f5;
    padding: 2px 12px;
    height: 48px !important;
    border-radius: 12px;
    /* display: flex;
    align-items: center; */
    /* justify-content: flex-start; */
    border: 2px solid #f5f5f5;
    line-height: 48px;

    &:focus {
      border-radius: 12px;
      border: 2px solid #0f6fdf;
    }
  }
  & ~ .text-error {
    position: absolute;
    bottom: 0;
  }
`;

export const SelectLableStyled = styled.div`
  padding: 6px 0;
  label {
    color: #232323;
    font-weight: 500;
    font-size: 14px;
  }
`;
