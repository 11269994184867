import React from "react";

const Arrow = () => {
  return (
    <>
      <svg
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fillOpacity="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 8.99998C6.83258 8.99998 6.67019 8.96563 6.51282 8.89692C6.35545 8.82822 6.22487 8.73662 6.12107 8.62212L0.345291 2.30152C0.115096 2.04961 -3.02308e-07 1.729 -2.34239e-07 1.33969C-1.66169e-07 0.950378 0.115097 0.629769 0.345291 0.377861C0.575485 0.125953 0.86846 1.51846e-07 1.22422 2.14049e-07C1.57997 2.76251e-07 1.87294 0.125954 2.10314 0.377861L7 5.73663L11.8969 0.377863C12.1271 0.125955 12.42 2.17159e-06 12.7758 2.23379e-06C13.1315 2.29599e-06 13.4245 0.125956 13.6547 0.377863C13.8849 0.629771 14 0.950381 14 1.33969C14 1.729 13.8849 2.04962 13.6547 2.30152L7.87892 8.62212C7.75336 8.75952 7.61734 8.85708 7.47085 8.91479C7.32436 8.9725 7.16741 9.00089 7 8.99998Z"
          fillOpacity="#232323"
        />
      </svg>
    </>
  );
};

export default Arrow;
