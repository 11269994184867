import LogoutIcon from "@mui/icons-material/Logout";
import { Box, MenuItem, Switch } from "@mui/material";
import Button from "@mui/material/Button";
import { Select } from "components";
import { motion } from "framer-motion";
import { useApiMutation } from "hooks/useApi/useApiHooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/storeHooks";
import { SIDEBAR_CLOSE, SIDEBAR_OPEN } from "styles/global.style";
import {
  MenuItemStyled,
  NavbarContainer,
  NavbarSelectStyled,
  NavbarUserProfStyled,
} from "./Navbar.style";

import ChangeLang from "changeLang/ChangeLang";
import { useRoleManager } from "services/useRoleManager";
import useSocket from "services/useSocket";
import Arrow from "./assets/Arrow";
import NavSelectIcon from "./assets/NavSelectIcon";
import {
  LoadMoreIcon,
  NotificationIcon,
  NotificationKeyIcon,
  TRANSFERICON,
} from "assets/svgs";
import dayjs from "dayjs";
import { socket } from "socket";
import { get } from "lodash";

const Navbar = ({ hasNavbar }: { hasNavbar: boolean }) => {
  const [newNotification, setNewNotification] = useState<any>();
  const handleChangeIsFree = useSocket();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const hasAccess = useRoleManager();
  const [limit, setLimit] = useState(10);
  const [limit2, setLimit2] = useState(10);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  useEffect(() => {
    socket.connect();
    socket.on("confirmContract", (data) => setNewNotification(data));

    return () => {
      socket.off("confirmContract", () => {});
    };
  }, []);

  const { mutate } = useApiMutation<{
    // _id: string;
    currentBranchId: string;
  }>("employee/branch", "post", {
    onSuccess(data, variables, context) {
      if (pathname?.split("/")?.some((path) => path?.length === 24)) {
        navigate("order/table");
      }
      navigate(0);
    },
  });
  const { value } = useAppSelector((state) => state.sideBarData);

  const { role, firstName, lastName, currentBranch, _id } = useAppSelector(
    (state) => state.LoginState.loginData
  );

  const { isConnected, isFree } = useAppSelector((state) => state.OrderState);

  // user box

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const openUser = Boolean(anchorElUser);
  const handleClickUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const logoutUser = () => {
    localStorage.clear();
    navigate("/login", {
      replace: false,
    });
  };

  const { mutate: getUnconfirmedContracts, data: unconfirmedContractsData } =
    useApiMutation(`contract/unconfirmed?page=1&limit=${limit}`, "get");

  // @ts-ignore
  const unconfirmedContracts = unconfirmedContractsData?.data?.data;

  const { mutate: getUnconfirmedContracts2, data: unconfirmedContractsData2 } =
    useApiMutation(`/transfer/uncompleted?page=1&limit=${limit2}`, "get", {
      onSuccess() {
        console.log("success");
      },
      onError(err) {
        console.log(err);
      },
    });

  // @ts-ignore
  const unconfirmedContracts2 = unconfirmedContractsData2?.data?.data;

  useEffect(() => {
    if (hasAccess("paymentConfirm")) {
      getUnconfirmedContracts("");
    }
  }, [limit, newNotification]);

  useEffect(() => {
    if (hasAccess("organizationalTransfer") || hasAccess("internalTransfer")) {
      getUnconfirmedContracts2("");
    }
  }, [limit2]);

  return (
    <NavbarContainer>
      <div
        style={{ left: value ? SIDEBAR_OPEN : SIDEBAR_CLOSE }}
        className="navbar-content"
      >
        <NavbarSelectStyled>
          <Select
            // optionsUrl="branch/all"
            iconValue={<NavSelectIcon />}
            options={role?.map((rol) => rol.branch)}
            // defaultFirst={true}
            customValue={currentBranch?._id || role?.[0].branch?._id}
            onChange={(val) =>
              mutate({
                currentBranchId: val as string,
                // _id: localStorage.getItem("employeeId") || "",
              })
            }
          />

          {(hasAccess("organizationalTransfer") ||
            hasAccess("internalTransfer")) && (
            <div className="notification">
              <div
                className="icon"
                onClick={() => {
                  if (!open2) {
                    getUnconfirmedContracts2("");
                  }
                  setOpen2(!open2);
                }}
              >
                <TRANSFERICON />
                {!!unconfirmedContracts2?.length && (
                  <span className="red"></span>
                )}
              </div>
              {open2 && (
                <div className="list">
                  <h4>Yakunlanmagan ko'chirishlar</h4>
                  {unconfirmedContracts2?.map((item: any) => (
                    <div
                      className="item"
                      key={item?._id}
                      onClick={() => {
                        if (get(item, "type", "") === "internal") {
                          navigate(`/internal-transfer/${item?._id}`);
                        } else {
                          navigate(`/organizational-transfer/${item?._id}`);
                        }
                        setOpen2(false);
                      }}
                    >
                      {/* <span className="key"><TRANSFERICON /></span> */}
                      <div className="text">
                        <span className="contract-number">
                          Ko'chirish &nbsp; #{item.number}
                        </span>
                        <span className="status">Jarayonda...</span>
                      </div>
                      <div className="time">
                        {dayjs(item.createdAt).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  ))}
                  <div className="load-more">
                    <span
                      className="load-more-icon"
                      onClick={() => setLimit((prev) => prev + 10)}
                    >
                      <LoadMoreIcon />
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </NavbarSelectStyled>
        <div className="navbar-right-container">
          {hasAccess("dispatcher") && (
            <Box>
              <Switch
                checked={isFree}
                onChange={handleChangeIsFree}
                disabled={!isConnected}
              />
            </Box>
          )}
          {hasAccess("paymentConfirm") && (
            <div className="notification">
              <div
                className="icon"
                onClick={() => {
                  if (!open) {
                    getUnconfirmedContracts("");
                  }
                  setOpen(!open);
                }}
              >
                <NotificationIcon />
                {!!unconfirmedContracts?.length && (
                  <span className="red"></span>
                )}
              </div>
              {open && (
                <div className="list">
                  <h4>Bildirishnomalar</h4>
                  {unconfirmedContracts?.map((item: any) => (
                    <div
                      className="item"
                      key={item?._id}
                      onClick={() => {
                        navigate(`/contract/view/${item?._id}`);
                      }}
                    >
                      <span className="key">
                        <NotificationKeyIcon />
                      </span>
                      <div className="text">
                        <span className="contract-number">
                          Shartnoma &nbsp; #{item.contractNumber}
                        </span>
                        <span className="status">Tasdiq kutilmoqda...</span>
                      </div>
                      <div className="time">
                        {dayjs(item.createdAt).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  ))}
                  <div className="load-more">
                    <span
                      className="load-more-icon"
                      onClick={() => setLimit((prev) => prev + 10)}
                    >
                      <LoadMoreIcon />
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
          <ChangeLang />
          <NavbarUserProfStyled>
            <Button
              id="basic-button"
              aria-controls={openUser ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openUser ? "true" : undefined}
              onClick={handleClickUser}
              className={anchorElUser ? "user_btn active" : "user_btn"}
            >
              <span>
                {firstName} {lastName}
              </span>
              <div className="icon_box">
                <Arrow />
              </div>
            </Button>

            <MenuItemStyled
              className="menu_user"
              id="basic-menu"
              anchorEl={anchorElUser}
              open={openUser}
              onClose={() => setAnchorElUser(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {/* <MenuItem className="salom">Profile</MenuItem>
                <MenuItem>My account</MenuItem> */}
              <MenuItem onClick={logoutUser}>
                <LogoutIcon className="icon" />
                Logout
              </MenuItem>
            </MenuItemStyled>
          </NavbarUserProfStyled>
        </div>
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
