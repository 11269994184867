import { Loading } from "components";
import { useApi } from "hooks/useApi/useApiHooks";
import { Suspense, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useRoleManager } from "services/useRoleManager";
import { ILoginData, setLoginData } from "store/reducers/LoginSlice";
import { useAppDispatch, useAppSelector } from "store/storeHooks";

const AuthUser = () => {
  const dis = useAppDispatch();
  const hasToken = !!localStorage.getItem("token");
  const navigate = useNavigate();
  const { isLoading, isFetching, isSuccess } = useApi<ILoginData>(
    "employee/auth",
    {},
    {
      enabled: hasToken,
      cacheTime: 0,
      staleTime: Infinity,
      onSuccess(data) {
        dis(setLoginData(data.data));
        localStorage.setItem("employeeId", data.data._id);
        if (!localStorage.getItem("i18nextLng")) {
          localStorage.setItem("i18nextLng", "uz");
        }
        navigate("/home");
      },
      onError() {
        // window.location.replace("/login");
      },
    }
  );
  if (isLoading || isFetching) {
    return <Loading />;
  }
  return <Outlet />;
};

export default AuthUser;
