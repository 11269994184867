import { Box, Typography } from "@mui/material";
import { Navbar, Sidebar } from "components";
import { motion } from "framer-motion";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { RootState } from "store/store";
import { useAppSelector } from "store/storeHooks";
import {
  PrivateContainer,
  SIDEBAR_CLOSE,
  SIDEBAR_OPEN,
} from "styles/global.style";
import { StyledHasOrder, StyledIconButton } from "./Layout.style";

const hasNavbar = (pathname: string) =>
  !["purchase/create", "contract/templates", "purchase/", "contract/view"].some(
    (path) => pathname.includes(path)
  );
const scrollPages = (pathname: string) =>
  [
    "order",
    "contract",
    "queue",
    "purchase",
    "income",
    "warehouse/products",
    "stock-car",
  ].some((path) => pathname.includes(path));
const Layout = () => {
  const navigator = useNavigate();
  const location = useLocation();

  const { value } = useAppSelector((state: RootState) => state.sideBarData);

  const { isOrder, orderId, customer } = useAppSelector(
    (state) => state.OrderState.order
  );
  const goToOrder = () => {
    navigator(`/order/order-person/${orderId}`);
  };
  const isAuth = useAppSelector((store) => store.LoginState.isAuth);
  return isAuth ? (
    <>
      {hasNavbar(location.pathname) ? <Navbar hasNavbar={true} /> : null}
      <Sidebar />

      {isOrder && !location?.pathname?.startsWith("/order/order-person") && (
        <StyledHasOrder>
          <StyledIconButton onClick={goToOrder}>
            <Typography
              fontSize="30px"
              fontWeight={500}
              textTransform="uppercase"
              color="#FFFFFF"
            >
              {customer?.firstName?.slice(0, 1)}
              {customer?.lastName?.slice(0, 1)}
            </Typography>
          </StyledIconButton>
        </StyledHasOrder>
      )}

      <motion.div
        animate={{
          // width: "100%",
          paddingLeft: value ? SIDEBAR_OPEN : SIDEBAR_CLOSE,
        }}
        style={{ minHeight: "100vh" }}
        className="home-container"
      >
        <PrivateContainer
          style={{
            paddingTop: hasNavbar(location.pathname) ? "65px" : "0",
            height: "100vh",
            overflow: "auto",
            boxSizing: "border-box",
          }}
        >
          <Box
            height={
              hasNavbar(location.pathname) ? "calc(100vh - 65px)" : "100vh"
            }
            paddingX={
              location?.pathname?.startsWith("/contract/templates")
                ? "0"
                : "24px"
            }
            paddingTop={
              location?.pathname?.startsWith("/contract/templates")
                ? "0px"
                : "10px"
            }
            sx={{
              overflowY: scrollPages(location.pathname) ? "scroll" : "auto",
              background: location?.pathname?.startsWith("/contract/templates")
                ? "#EAEEF1"
                : "#FFFFFF",
            }}
          >
            <Outlet />
          </Box>
        </PrivateContainer>
      </motion.div>
    </>
  ) : null;
};

export default Layout;
