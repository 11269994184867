import styled from "styled-components";

export const AutoCompleteStyled = styled.div<any>`
  //! @dangerous qolgan autocompletelar ham stili shunda
  margin: 0 !important;

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #32324d;
    padding: 5px 0 !important;
    span {
      color: red;
    }
  }
  .MuiInputBase-root {
    padding: 0 60px 0 4px !important;
    height: ${({ multiple }) => (multiple ? "auto" : "42px")} !important;
    min-width: 180px;
    background-color: #f5f5f5;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    margin: 0 !important;

    .MuiOutlinedInput-input {
      /* padding-left: 1rem !important; */
    }
  }
  h6.text-error {
    position: absolute;
    bottom: 0;
  }
  position: relative;

  .MuiOutlinedInput-root {
    border: 2px solid #f5f5f5 !important;

    transition: all 0.3s ease;
    &:hover {
      border: 2px solid #0f6fdf !important;
    }
  }
  .MuiChip-root {
    width: ${({ multiple }) => (multiple ? "auto" : "")};
    max-width: ${({ multiple }) => (multiple ? "150px" : "")} !important;
  }
`;
