import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrder } from "types/common.types";
export type IOrderData = typeof initialState;
const initialState = {
  isConnected: false,
  isFree: false,
  manager: {
    status: "initial",
    reason: "",
    orderId: "",
  },
  order: {
    isOrder: false,
    orderId: "",
    customer: {
      _id: "",
      firstName: "",
      lastName: "",
    },
  },
};

const OrderSlice = createSlice({
  name: "order-data",
  initialState,
  reducers: {
    setSocketConnect(state, payload: PayloadAction<boolean>) {
      state.isConnected = payload.payload;
    },
    setIsFree(state, payload: PayloadAction<boolean>) {
      state.isFree = payload.payload;
    },
    startOrder(state, payload: PayloadAction<IOrder>) {
      state.order.isOrder = true;
      state.order.orderId = payload?.payload?._id;
      state.order.customer = payload?.payload?.customer;
    },
    finishedOrder(state) {
      state.order.isOrder = false;
      state.order.orderId = "";
      state.order.customer = {
        _id: "",
        firstName: "",
        lastName: "",
      };
      state.isFree = false
    },
    changeManager(
      state,
      payload: PayloadAction<{
        status: string;
        reason?: string;
        orderId?: string;
      }>
    ) {
      state.manager.status = payload.payload.status;
      state.manager.reason = payload.payload?.reason || "";
      state.manager.orderId = payload.payload?.orderId || "";
    },
  },
});

export const {
  setSocketConnect,
  setIsFree,
  startOrder,
  finishedOrder,
  changeManager,
} = OrderSlice.actions;

export default OrderSlice.reducer;
