import { ILoginData } from "store/reducers/LoginSlice";
import { useAppSelector } from "store/storeHooks";

export type IRoles_ = ILoginData["role"][number]["role"];

export const useRoleManager = () => {
  const { role, currentBranch, isSuperAdmin } = useAppSelector(
    (store) => store.LoginState.loginData
  );

  // I used "_id" prop for public roles and to home page
  const hasAccess = (roleName: keyof IRoles_ = "_id") => {
    if (isSuperAdmin) {
      return true;
    }
    if (roleName === "_id") {
      return true;
    }
    try {
      const currentRole = role.find(
        (fRole) => fRole.branchId === currentBranch._id
      )!;
      const checkAccess = currentRole?.role[roleName];
      return !!checkAccess;
    } catch (err) {
      return false;
    }
  };
  return hasAccess;
};
