import React from "react";

const CirculeDone = () => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="14" cy="14" r="14" fill="#0F6FDF" />
        <path
          d="M11.575 16.175L17.475 10.275C17.6583 10.0917 17.8917 10 18.175 10C18.4583 10 18.6917 10.0917 18.875 10.275C19.0583 10.4583 19.15 10.6917 19.15 10.975C19.15 11.2583 19.0583 11.4917 18.875 11.675L12.275 18.275C12.075 18.475 11.8417 18.575 11.575 18.575C11.3083 18.575 11.075 18.475 10.875 18.275L8.275 15.675C8.09167 15.4917 8 15.2583 8 14.975C8 14.6917 8.09167 14.4583 8.275 14.275C8.45833 14.0917 8.69167 14 8.975 14C9.25833 14 9.49167 14.0917 9.675 14.275L11.575 16.175Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default CirculeDone;
