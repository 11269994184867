import { useNavigate } from "react-router-dom";
import { MainButton, PhoneInput, TextInput } from "components";
import { LoginStyled } from "./Login.style";
import Brand from "../assets/loginBrand.svg";
import BrandText from "../assets/loginBrandText.svg";
import UnicalLogo from "../assets/unicalLogo.svg";
import LoginLeft1 from "../assets/loginLeft1.png";
import LoginLeft2 from "../assets/loginLeft2.png";
import LoginLeft3 from "../assets/loginLeft3.png";
import LoginRight1 from "../assets/loginRight1.svg";
import LoginRight2 from "../assets/loginRight2.svg";
import { useForm } from "react-hook-form";
import { useApiMutation } from "hooks/useApi/useApiHooks";
import { useAppDispatch } from "store/storeHooks";
import { ILoginData, setLoginData } from "store/reducers/LoginSlice";
import { toast } from "react-toastify";

interface ILoginBody {
  phoneNumber: string;
  password: string;
}
const Login = () => {
  const { control, handleSubmit } = useForm<ILoginBody>();
  const dis = useAppDispatch();
  const navigate = useNavigate();
  const url = window.location.href;
  const isUnical = url === "https://unical-dev-bestune.kahero.uz/login";

  const { mutate, status } = useApiMutation<ILoginBody, ILoginData>(
    "employee/login",
    "post",
    {
      onSuccess(data) {
        dis(setLoginData(data.data));
        toast.success("Muvaffaqiyatli");
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("employeeId", data.data._id);
        localStorage.setItem("i18nextLng", "uz");

        navigate("/home");
      },
    }
  );

  const submit = (data: ILoginBody) => {
    mutate(data);
  };
  return (
    <LoginStyled>
      <div className="login-left d-flex flex-column align-items-center justify-content-center">
        <img src={LoginLeft1} alt="loginleft1" className="loginleft1" />
        <img src={LoginLeft2} alt="loginleft2" className="loginleft2" />
        <img src={LoginLeft3} alt="loginleft3" className="loginleft3" />
        <form onSubmit={handleSubmit(submit)}>
          <h1>Welcome back</h1>
          <main>
            <span>
              <PhoneInput
                control={control}
                name="phoneNumber"
                label="Phone number"
                autofocus={true}
              />
            </span>
            <span>
              <TextInput
                control={control}
                name="password"
                type="password"
                label="Password"
                placeholder="Password"
              />
            </span>
            <span>
              <MainButton
                disabled={status === "loading"}
                // loader
                title="Login"
                type="submit"
                variant="contained"
              />
            </span>
          </main>
        </form>
      </div>
      <div className="login-right">
        {!isUnical && (
          <>
            <img src={LoginRight1} alt="loginright1" className="loginright1" />
            <img src={LoginRight2} alt="loginright2" className="loginright2" />
          </>
        )}
        {isUnical ? (
          <div className="unical-logo">
            <img src={UnicalLogo} alt="unical" />
          </div>
        ) : (
          <div className="login-brand-box d-flex flex-column align-items-center justify-content-top gap-6">
            <img src={Brand} alt="Barnd" />
            <img src={BrandText} alt="Text" />
          </div>
        )}

        <p className="login-text-first">
          © All rights reserved. BESTUNE CRM - 2023
        </p>
        <p className="login-text-second">UNICAL SOLUTIONS</p>
      </div>
    </LoginStyled>
  );
};

export default Login;
