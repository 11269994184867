import React from "react";
import { StyledButtonStyled } from "./StyledButton.style";
import { ButtonProps } from "@mui/material";

interface IStyledButton extends ButtonProps {
  bg?: string;
  tint?: string;
  title: string;
  height?: number;
  disabled?: boolean;
}
const StyledButton: React.FC<IStyledButton> = ({
  bg,
  tint,
  title,
  height,
  disabled,
  ...props
}) => {
  return (
    <StyledButtonStyled
      bg={bg}
      tint={tint}
      height={height}
      disabled={disabled}
      {...props}
    >
      {title || "Qo'shish"}
    </StyledButtonStyled>
  );
};

export default StyledButton;
