import { Button } from "@mui/material";
import styled from "styled-components";

interface IMainBtnStyled {
  variant?: "text" | "outlined" | "contained";
}

export const StyledButton = styled(Button)<IMainBtnStyled>((props) => ({
  minWidth: "182px !important",
  height: "48px",
  fontFamily: "'Inter', sans-serif !important",
  fontWeight: "600 !important",
  fontSize: "14px !important",
  borderRadius: "12px !important",
  lineHeight: "48px",
  // backgroundColor: props?.variant === "contained" ? "#0F6FDF !important" : "",
}));
