import React from "react";
import PaidIcon from "@mui/icons-material/Paid";
import styled from "@emotion/styled";

const PaymentIcon = () => {
  return (
    <StyledPaymentIcon>
      <PaidIcon />
    </StyledPaymentIcon>
  );
};

export default PaymentIcon;

const StyledPaymentIcon = styled.span`
  svg {
    path {
      fill: #fff;
    }
  }
`;
