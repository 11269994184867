import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import { IRoles_ } from "services/useRoleManager";

const Order = lazy(() => import("pages/order"));
const ClientPage = lazy(() => import("pages/clientPage"));
const Contract = lazy(() => import("pages/contract"));
const Cashbox = lazy(() => import("pages/cashbox"));
const Queues = lazy(() => import("pages/queues"));
const TermPayment = lazy(() => import("pages/termPayment"));
const Employees = lazy(() => import("pages/employees"));

const Purchase = lazy(() => import("pages/barn/purchase"));
const Income = lazy(() => import("pages/barn/income"));
const Car = lazy(() => import("pages/barn/car"));
const Warehouse = lazy(() => import("pages/barn/warehouse"));
const CarHistory = lazy(() => import("pages/barn/carHistory/CarHistory"));
const CarQuality = lazy(
  () => import("pages/barn/carStock/components/CarQuality/CarQuality")
);
const Supplier = lazy(() => import("pages/barn/supplier"));
const Cash = lazy(() => import("pages/barn/cash"));
const CarStock = lazy(() => import("pages/barn/carStock"));
const CarStats = lazy(() => import("pages/barn/carStats"));
const Transfer = lazy(() => import("pages/barn/transfer"));
const Inventory = lazy(() => import("pages/barn/inventory"));

const ServiceReport = lazy(() => import("pages/service/report/ServiceReport"));
const ServiceReason = lazy(() => import("pages/service/serviceReason/index"));
const Service = lazy(() => import("pages/service/service"));

const Dashboard = lazy(() => import("pages/stats/dashboard"));
const ManagerStats = lazy(() => import("pages/stats/manager"));
const ContractBranch = lazy(() => import("pages/stats/contractBranch"));
const ContractManager = lazy(() => import("pages/stats/contractManager"));
const ContractCar = lazy(() => import("pages/stats/contractCar"));
const ParkedCars = lazy(() => import("pages/stats/parkedCars"));
const CarBranch = lazy(() => import("pages/stats/carBranch"));
const CarWarehouse = lazy(() => import("pages/stats/carWarehouse"));
const CarReport = lazy(() => import("pages/stats/carReport"));
const SoldCar = lazy(() => import("pages/stats/soldCar"));

const Reason = lazy(() => import("pages/settings/reason"));
const TransferReason = lazy(() => import("pages/settings/transferReason"));
const VinCodeStatus = lazy(
  () => import("pages/service/vinStatus/container/VinStatus")
);
const CarStatusStats = lazy(() => import("pages/service/inService/InService"));
const Roles = lazy(() => import("pages/settings/roles"));
const Transactions = lazy(() => import("pages/settings/transactions"));
const CarBrand = lazy(() => import("pages/settings/carBrand"));
const Currency = lazy(() => import("pages/settings/carrency"));
const Branch = lazy(() => import("pages/settings/branch"));
const CarModel = lazy(() => import("pages/settings/carModel"));
const CarColor = lazy(() => import("pages/settings/carColor"));
const PaymentMethods = lazy(() => import("pages/settings/paymentMethod"));
const Vat = lazy(() => import("pages/settings/vat/Vat"));
const Bank = lazy(() => import("pages/settings/bank"));
const Company = lazy(() => import("pages/settings/companies"));
const BLACK_PAGE = lazy(() => import("pages/black_page"));

const privateRoutes: (RouteObject & { role: keyof IRoles_ })[] = [
  {
    element: <Navigate to="/home" replace />,
    path: "*",
    role: "_id",
  },
  {
    element: <Navigate to="/home" replace />,
    path: "/",
    role: "_id",
  },
  {
    element: <BLACK_PAGE />,
    path: "home",
    role: "_id",
  },
  {
    element: <Order />,
    path: "order/*",
    role: "order",
  },
  {
    element: <ClientPage />,
    path: "client",
    role: "customer",
  },
  {
    element: <Contract />,
    path: "contract/*",
    role: "contract",
  },
  // {
  //   element: <Cashbox />,
  //   path: "cashbox",
  //   role: "cashbox",
  // },
  {
    element: <Queues />,
    path: "queues",
    role: "queue",
  },
  {
    element: <TermPayment />,
    path: "/term-payment",
    role: "queue",
  },

  {
    element: <Employees />,
    path: "employees",
    role: "employee",
  },
  // ? barn routes
  {
    element: <Purchase />,
    path: "/purchase/*",
    role: "purchase",
  },
  {
    element: <Income />,
    path: "/income/*",
    role: "income",
  },
  {
    element: <Car />,
    role: "car",
    path: "/cars",
  },
  {
    element: <Warehouse />,
    path: "/warehouse/*",
    role: "warehouse",
  },
  {
    element: <CarHistory />,
    path: "/car-history/:id",
    role: "warehouse",
  },
  {
    element: <CarQuality />,
    path: "/car-quality/:id",
    role: "markStateUpdate",
  },
  // {
  //   element: <Cash />,
  //   path: "/add-cash",
  //   role: "cashbox",
  // },
  // {
  //   element: <Supplier />,
  //   path: "/supplier",
  //   role: "supplier",
  // },
  {
    element: <CarStock />,
    path: "/stock-car",
    role: "carStock",
  },
  // {
  //   element: <CarStats />,
  //   path: "/car-stats",
  //   role: "car",
  // },
  {
    element: <Transfer />,
    path: "/internal-transfer/*",
    role: "internalTransfer",
  },
  {
    element: <Transfer />,
    path: "/organizational-transfer/*",
    role: "organizationalTransfer",
  },
  {
    element: <Inventory />,
    path: "/inventory/*",
    role: "organizationalTransfer",
  },

  // ? Service routes
  {
    element: <Service />,
    path: "/services",
    role: "markStateUpdate",
  },
  {
    element: <ServiceReport />,
    path: "/service-report",
    role: "markStateUpdate",
  },
  {
    element: <ServiceReason />,
    path: "/service-reason",
    role: "markStateUpdate",
  },
  {
    element: <VinCodeStatus />,
    path: "/vincode-status",
    role: "markStateUpdate",
  },
  {
    element: <CarStatusStats />,
    path: "/car-status-stats",
    role: "markStateUpdate",
  },

  // ? Statistics routes
  {
    element: <Dashboard />,
    path: "/dashboard",
    role: "managerStatistics",
  },
  {
    element: <ManagerStats />,
    path: "/manager-stats",
    role: "managerStatistics",
  },
  {
    element: <ContractBranch />,
    path: "/contractstats-branch",
    role: "managerStatistics",
  },
  {
    element: <ContractManager />,
    path: "/contractstats-manager",
    role: "managerStatistics",
  },
  {
    element: <ContractCar />,
    path: "/contractstats-car",
    role: "managerStatistics",
  },
  {
    element: <ParkedCars />,
    path: "/parked-cars",
    role: "managerStatistics",
  },
  {
    element: <CarBranch />,
    path: "/carstats-branch",
    role: "managerStatistics",
  },
  {
    element: <CarWarehouse />,
    path: "/carstats-warehouse",
    role: "managerStatistics",
  },
  {
    element: <CarReport />,
    path: "/carstats-car",
    role: "managerStatistics",
  },
  {
    element: <SoldCar />,
    path: "/sold-car",
    role: "managerStatistics",
  },
  // ? Setting routes
  {
    element: <Branch />,
    path: "/branch",
    role: "branch",
  },
  {
    element: <Roles />,
    path: "/roles",
    role: "role",
  },
  {
    element: <Bank />,
    path: "/bank",
    role: "contract",
  },
  // {
  //   element: <Reason />,
  //   path: "/reason",
  //   role: "reason",
  // },
  {
    element: <TransferReason />,
    path: "/transfer-reason",
    role: "transferReason",
  },
  {
    element: <Currency />,
    path: "/currencies",
    role: "currency",
  },
  {
    element: <CarBrand />,
    path: "/car-brand",
    role: "carBrand",
  },
  {
    element: <CarModel />,
    path: "/car-model",
    role: "carModel",
  },
  {
    element: <CarColor />,
    path: "/car-color",
    role: "carColor",
  },
  // {
  //   element: <Transactions />,
  //   path: "/typesOfTransactions",
  //   role: "transactionType",
  // },
  // {
  //   element: <PaymentMethods />,
  //   path: "/payment-methods",
  //   role: "paymentMethod",
  // },
  {
    element: <Vat />,
    path: "/vat",
    role: "contract",
  },
  {
    element: <Company />,
    path: "/company",
    role: "_id", // @role
  },
];

export default privateRoutes;
