import styled from "styled-components";

export const LoginStyled = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;

  .login-left {
    position: relative;
    width: 50%;
    height: 100%;
    background: #ffffff;
    overflow: hidden;

    form {
      display: flex;
      flex-direction: column;
      gap: 50px;
      min-width: 335px;

      .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        border: none !important;
      }

      h1 {
        font-size: 48px;
        color: #232323;
        font-weight: 600;
        font-family: "SF Pro Display";
        font-style: normal;
        font-size: 48px;
        line-height: 57px;
        display: flex;
        align-items: center;
      }

      main {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      button {
        width: 100%;
        height: 56px;
      }
      .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        background-color: #f5f5f5;
        border-radius: 12px;
        border: none;
      }
      .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
        height: 42px;
        width: 42px;
      }
    }

    .loginleft1 {
      position: absolute;
      top: 0;
      left: 0;
    }
    .loginleft2 {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .loginleft3 {
      position: absolute;
      right: -450px;
      bottom: 0;
    }
  }
  .login-right {
    position: relative;
    background: blue;
    width: 50%;
    height: 100%;
    background: #2a3042;
    overflow: hidden;

    .login-brand-box {
      margin-top: 158px;
      height: 100%;
      z-index: 11;
    }

    .loginright1 {
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .loginright2 {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 90px;
      transform: rotate(360deg);
    }

    .login-text-first {
      position: absolute;
      bottom: 16px;
      left: 24px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.5);
    }
    .login-text-second {
      position: absolute;
      bottom: 16px;
      right: 24px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.5);
    }
    .unical-logo {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
      }
    }
  }
`;
