import { ISidebarRoute } from "../sidebar.types";
import {
  Bugalteriya,
  Kassa,
  Mijoz,
  Navbat,
  Ombor,
  Order,
  Setting,
  Xodim,
} from "./SidebarIcons/Icons";
import PaymentIcon from "./SidebarIcons/PaymentIcon";

export const sidebarRoutes: ISidebarRoute[] = [
  {
    icon: <Order />,
    title: "Buyurtmalar",
    path: "/order/table",
    translate: "ORDERS",
    role: "order",
  },

  {
    icon: <Mijoz />,
    title: "Mijozlar",
    path: "/client",
    translate: "CLIENTS",
    role: "customer",
  },
  {
    icon: <Bugalteriya />,
    title: "Bugalteriya",
    path: "/contract",
    role: "contract",
    translate: "BUGALTERIA",
  },
  // {
  //   icon: <Kassa />,
  //   title: "Kassa",
  //   path: "/cashbox",
  //   role: "cashbox",
  //   translate: "KASSA",
  // },
  {
    icon: <Navbat />,
    title: "Navbatlar",
    path: "/queues",
    role: "queue",
    translate: "QUEUES",
  },
  {
    icon: <PaymentIcon />,
    title: "Muddatli to'lov",
    path: "/term-payment",
    role: "installment", // ! it needs to change to termPayment
    translate: "TERM_PAYMENT",
  },
  {
    icon: <Xodim />,
    title: "Xodimlar",
    path: "/employees",
    role: "employee",
    translate: "EMPLOYEE",
  },
  {
    icon: <Ombor />,
    title: "Ombor",
    role: "warehouse",
    translate: "BARN",
    items: [
      {
        title: "Xaridlar",
        path: "/purchase",
        translate: "BARN_ALL.SHOPPING",
        role: "purchase",
      },
      {
        title: "Kirimlar",
        path: "/income",
        translate: "BARN_ALL.ENTRIES",
        role: "income",
      },
      {
        title: "Mashinalar",
        path: "/cars",
        translate: "BARN_ALL.CARS",
        role: "car",
      },
      {
        title: "Omborlar",
        path: "/warehouse",
        translate: "BARN_ALL.BARN",
        role: "warehouse",
      },
      // {
      //   title: "Kassa qo'shish",
      //   path: "/add-cash",
      //   role: "cashbox",
      //   translate: "BARN_ALL.CASHADDING",
      // },
      // {
      //   title: "Yetkazib beruvchilar",
      //   path: "/supplier",
      //   translate: "BARN_ALL.SUPPLIER",
      //   role: "supplier",
      // },
      {
        title: "Mashina hisoboti",
        path: "/stock-car",
        translate: "BARN_ALL.MACHINE_REPORT",
        role: "carStock",
      },
      // {
      //   title: "Mashina Statistikasi",
      //   path: "/car-stats",
      //   translate: "BARN_ALL.MACHINE_STATS",
      //   role: "carStatistics",
      // },
      {
        title: "Ichki ko'chirish",
        path: "/internal-transfer",
        translate: "BARN_ALL.INTERNAL_TRANSFER",
        role: "internalTransfer",
      },
      {
        title: "O'zaro ko'chirish",
        path: "/organizational-transfer",
        translate: "BARN_ALL.ORG_TRANSFER",
        role: "organizationalTransfer",
      },
      {
        title: "Inventarizatsiya",
        path: "/inventory",
        translate: "BARN_ALL.INVENTORY",
        role: "markStateUpdate",
      },
    ],
  },
  {
    icon: <Order />,
    title: "Servis",
    role: "markStateUpdate",
    translate: "SERVICE",
    items: [
      {
        title: "Vinkod holatlari",
        path: "/vincode-status",
        translate: "SETTINGS_ALL.VINCODE_STATUS",
        role: "markStateUpdate",
      },
      {
        title: "Mashina holati hisoboti",
        path: "/car-status-stats",
        translate: "SETTINGS_ALL.CAR_STATUS_STATS",
        role: "markStateUpdate",
      },
      {
        title: "Servis hisoboti",
        path: "/service-report",
        translate: "SERVICE_REPORT",
        role: "markStateUpdate",
      },
      {
        title: "Servislar",
        path: "/services",
        translate: "SERVICES",
        role: "markStateUpdate",
      },
      {
        title: "Sabablar",
        path: "/service-reason",
        translate: "SERVICE_REASON",
        role: "markStateUpdate",
      },
    ],
  },
  {
    icon: <Navbat />,
    title: "Statistika",
    role: "managerStatistics",
    translate: "STATISTICS",
    items: [
      {
        title: "Dashboard",
        path: "/dashboard",
        translate: "STATS.DASHBOARD",
        role: "managerStatistics",
      },
      {
        title: "Menejerlar statistikasi",
        path: "/manager-stats",
        translate: "BARN_ALL.MANAGER_STATS",
        role: "managerStatistics",
      },
      {
        title: "Shartnoma hisoboti (Filial)",
        path: "/contractstats-branch",
        translate: "STATS.CONTRACT_STATS_BRANCH",
        role: "managerStatistics",
      },
      {
        title: "Shartnoma hisoboti (Menejer)",
        path: "/contractstats-manager",
        translate: "STATS.CONTRACT_STATS_MANAGER",
        role: "managerStatistics",
      },
      {
        title: "Shartnoma hisoboti (Mashina)",
        path: "/contractstats-car",
        translate: "STATS.CONTRACT_STATS_CAR",
        role: "managerStatistics",
      },
      {
        title: "Ko'p turgan mashinalar",
        path: "/parked-cars",
        translate: "STATS.PARKED_CARS",
        role: "managerStatistics",
      },
      {
        title: "Mashina hisoboti (Filial)",
        path: "/carstats-branch",
        translate: "STATS.CAR_STATS_BRANCH",
        role: "managerStatistics",
      },
      {
        title: "Mashina hisoboti (Ombor)",
        path: "/carstats-warehouse",
        translate: "STATS.CAR_STATS_WAREHOUSE",
        role: "managerStatistics",
      },
      {
        title: "Mashina hisoboti",
        path: "/carstats-car",
        translate: "STATS.CAR_STATS_REPORT",
        role: "managerStatistics",
      },
      {
        title: "Sotilgan mashinalar",
        path: "/sold-car",
        translate: "STATS.SOLD_CAR",
        role: "managerStatistics",
      },
    ],
  },
  {
    icon: <Setting />,
    title: "Sozlamalar",
    translate: "SETTINGS",

    items: [
      {
        title: "Filiallar",
        path: "/branch",
        role: "branch",
        translate: "SETTINGS_ALL.BRANCHES",
      },
      {
        title: "Companialar",
        path: "/company",
        role: "company", // @role
        translate: "SETTINGS_ALL.COMPANIES",
      },
      {
        title: "Ruxsatlar",
        path: "/roles",
        role: "role",
        translate: "SETTINGS_ALL.ROLES",
      },
      {
        title: "Bank",
        path: "/bank",
        role: "bank",
        translate: "SETTINGS_ALL.BANK",
      },
      // {
      //   title: "Sabablar",
      //   path: "/reason",
      //   translate: "SETTINGS_ALL.REASONS",
      //   role: "reason",
      // },
      {
        title: "Ko'chirish sabablari",
        path: "/transfer-reason",
        translate: "SETTINGS_ALL.TRANSFER_REASON",
        role: "transferReason",
      },
      {
        title: "Valyutalar",
        path: "/currencies",
        translate: "SETTINGS_ALL.CURRENCY",
        role: "currency",
      },
      {
        title: "Mashina markalari",
        path: "/car-brand",
        translate: "SETTINGS_ALL.CAR_MARKS",
        role: "carBrand",
      },
      {
        title: "Mashina modeli",
        path: "/car-model",
        translate: "SETTINGS_ALL.CAR_MODEL",
        role: "carModel",
      },
      {
        title: "Mashina rangi",
        path: "/car-color",
        translate: "SETTINGS_ALL.CAR_COLOR",
        role: "carColor",
      },
      // {
      //   title: "Tranzaksiya turlari",
      //   path: "/typesOfTransactions",
      //   translate: "SETTINGS_ALL.TRANSACTION_TYPE",
      //   role: "transactionType",
      // },
      // {
      //   title: "To'lov usuli",
      //   path: "/payment-methods",
      //   translate: "SETTINGS_ALL.PAYMENT_METHOD",
      //   role: "paymentMethod",
      // },
      {
        title: "QQS foizi",
        path: "/vat",
        translate: "SETTINGS_ALL.VAT",
        role: "vat",
      },
    ],
  },
];
