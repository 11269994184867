import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker as DatePickerMUI } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Control, Controller, FieldPath } from "react-hook-form";
import { TRules } from "types/form.types";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePickerStyled } from "./DateTimePickerForm.style";
import { DateTimePicker } from "@mui/x-date-pickers";

type TDateVal = string | null;

interface IDatePicker<FormNames extends Record<string, any>> {
  control: Control<FormNames>;
  name: FieldPath<FormNames>;
  rules?: TRules<FormNames>;
  label?: string;
  disableTime?: boolean;
  minDate?: Date;
  maxDate?: Date;
  withTime?: boolean;
}
const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-(\d{4})$/;
export const DATE_FORMAT = "DD-MM-YYYY";
export const DATE_TIME_FORMAT = "DD-MM-YYYY | HH:mm";

function DatePickerForm<FormNames extends Record<string, any>>({
  control,
  name,
  disableTime = false,
  minDate,
  maxDate,
  withTime,
  rules = {
    required: {
      value: true,
      message: "Majburiy qator",
    },
    // pattern: disableTime
    //   ? {
    //       value: dateRegex,
    //       message: "Sanani to'g'ri kiriting",
    //     }
    //   : undefined,
    // pattern: {
    //   value:
    // },
    // deps
  },
  label,
}: IDatePicker<FormNames>) {
  const format = withTime
    ? DATE_TIME_FORMAT
    : disableTime
    ? DATE_FORMAT
    : `${DATE_FORMAT}`;

  return (
    <DateTimePickerStyled>
      <Controller
        control={control}
        name={name}
        rules={rules}
        // @ts-ignores😐
        defaultValue={null}
        render={({ field, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {withTime ? (
              <DateTimePicker
                {...field}
                // ampm={false}
                inputFormat={format}
                minDate={minDate ? minDate : null}
                maxDate={maxDate ? maxDate : null}
                // views={disableTime ? ["year", "month", "day"] : undefined}
                renderInput={(pickerProps) => (
                  <>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#32324d",
                        padding: "5px 0",
                      }}
                    >
                      {label}
                    </span>
                    <TextField
                      {...pickerProps}
                      inputProps={{
                        ...pickerProps.inputProps,
                        placeholder: dayjs().format(format),
                      }}
                      placeholder="oy"
                      // label="Sana"
                      fullWidth
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </>
                )}
              />
            ) : (
              <DatePicker
                {...field}
                // ampm={false}
                inputFormat={format}
                minDate={minDate ? minDate : null}
                maxDate={maxDate ? maxDate : null}
                // views={disableTime ? ["year", "month", "day"] : undefined}
                renderInput={(pickerProps) => (
                  <>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#32324d",
                        padding: "5px 0",
                      }}
                    >
                      {label}
                    </span>
                    <TextField
                      {...pickerProps}
                      inputProps={{
                        ...pickerProps.inputProps,
                        placeholder: dayjs().format(format),
                      }}
                      placeholder="oy"
                      // label="Sana"
                      fullWidth
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                    />
                  </>
                )}
              />
            )}
            {error && (
              <>
                <h6 className="text-error mt-1">
                  {label} {error.message}
                </h6>
              </>
            )}
          </LocalizationProvider>
        )}
      />
    </DateTimePickerStyled>
  );
}
export default DatePickerForm;
