import styled from "styled-components";
export const StepFormStyled = styled.div`
  .button_box {
    background-color: #ffffff;
    height: 80px;
    margin-bottom: -10px !important;
    border-top: 1px solid #d9d9d9;
  }
  .submit-button {
    background-color: #1976d2;
    width: 182px;
    height: 48px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 12px;
    border: none;
    color: white;
    cursor: pointer;
    transition: all 0.3s;
    &:active {
      background-color: #1976d299;
    }
  }
`;
export const PurchaseStyledModal = styled.div`
  .purchase_style {
    width: 550px;
    height: 354px;
    border-radius: 24px;
    background-color: #ffffff;
    padding: 12px;

    .delete_box {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon_box {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background: rgba(235, 114, 103, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          color: #eb5757;
          font-size: 45px;
        }
      }
    }
    background-color: red !important;

    .title_box {
      display: flex;
      justify-content: center;
      align-items: center;
      .MuiTab-root {
      }
      h2 {
        color: #000000;
        font-weight: 600;
        font-size: 32px;
      }
    }

    .btn_box {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn_content {
        display: flex;
        justify-content: center;
        align-items: center;

        .bekor_btn {
          width: 200px;
          height: 64px;
          background-color: #2d68f6;
          border-radius: 16px;
          color: #ffffff;
          font-size: 16px;
          font-weight: 600;
        }

        .qolish_btn {
          height: 64px;
          width: 200px;
          background-color: #eef3fe;
          border-radius: 16px;
          color: #2d68f6;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
`;
