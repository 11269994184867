import styled from "styled-components";

export const DateTimePickerStyled = styled.div`
  .sc-cUEOzv {
    border: 2px solid green;
  }
  .MuiFormControl-root {
    border: 2px solid #f5f5f5;
    border-radius: 12px;
    background-color: #f5f5f5;

    &:hover {
      border: 2px solid #0f6fdf !important;
    }

    label {
      color: #232323;
    }
  }
  position: relative;
  .text-error {
    position: absolute;
    bottom: 0;
  }
`;
