import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { MainButton, Modal } from "components";
import CirculeDone from "pages/barn/purchase/assets/CirculeDone";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PurchaseStyledModal, StepFormStyled } from "./StepModal.style";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StepForm = ({
  FirstStep,
  SecondStep,
  trigger,
  disabledNext,
  backUrl,
  loading,
  isDirty,
}: {
  FirstStep: React.ReactNode;
  SecondStep: React.ReactNode;
  trigger: any;
  disabledNext?: boolean;
  backUrl: string;
  isDirty: boolean;
  loading: boolean;
}) => {
  const [actionValue, setActionValue] = React.useState<0 | 1>(0); // ! must be 0
  const [warningModal, setWarningModal] = React.useState(false);
  const navigate = useNavigate();
  const handleChangeAction = (event: React.SyntheticEvent, newValue: 0 | 1) => {
    if (actionValue < newValue) {
      trigger().then((isValid: boolean) => {
        if (isValid) {
          setActionValue(newValue);
        }
      });

      return;
    }
    trigger().then((isValid: boolean) => {
      if (isValid) {
        setActionValue(newValue);
      }
    });
  };

  const clickHandler = () => {
    trigger().then((isValid: boolean) => {
      if (isValid) {
        if (actionValue === 0) {
          setActionValue(1);
        }
      }
    });
  };

  const { t } = useTranslation();

  return (
    <StepFormStyled>
      <Box className="tab-list">
        <Box>
          <Tabs
            value={actionValue}
            onChange={handleChangeAction}
            aria-label="basic tabs example"
            sx={{ "& .MuiTabs-indicator": { display: "none" } }}
          >
            <Tab
              label={
                <span className="title_header">
                  {actionValue > 0 ? <CirculeDone /> : "1"}
                </span>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <span className="title_header">
                  {actionValue > 1 ? <CirculeDone /> : "2"}
                </span>
              }
              {...a11yProps(1)}
            />
            {/* <Tab
              label={
                <span className="title_header">
                  {actionValue > 2 ? <CirculeDone /> : "3"}
                </span>
              }
              {...a11yProps(2)}
            /> */}
          </Tabs>
        </Box>
        <TabPanel value={actionValue} index={0}>
          {FirstStep}
        </TabPanel>
        <TabPanel value={actionValue} index={1}>
          {SecondStep}
        </TabPanel>
        {/* <TabPanel value={actionValue} index={2}>
          {ThirdStep}
        </TabPanel> */}
      </Box>
      <div className="btn_box button_box">
        {actionValue === 1 && (
          <MainButton
            title="Ortga qaytish" // @translate
            variant="outlined"
            // className="close_btn"
            onClick={() => setActionValue(0)}
          />
        )}
        {actionValue === 0 ? (
          <button
            className="submit-button"
            // disabled={actionValue !== 0 ? disabledNext : false}
            disabled={backUrl.includes("transfer") && disabledNext}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              clickHandler();
            }}
          >
            {t("BUTTONS_TITLE.CONTINIOUS_BUTTON")!}
          </button>
        ) : (
          <button
            className="submit-button"
            // disabled={actionValue !== 0 ? disabledNext : false}
            type={"submit"}
            onClick={clickHandler}
          >
            {t("BUTTONS_TITLE.END_BUTTON")!}
          </button>
        )}
      </div>
      <Modal
        open={warningModal}
        setOpen={setWarningModal}
        onClose={() => setWarningModal(false)}
      >
        <PurchaseStyledModal>
          <Grid container className="purchase_style">
            <Grid item md={12} className="delete_box">
              <div className="icon_box">
                <DeleteIcon className="icon" />
              </div>
            </Grid>
            <Grid item md={12} className="title_box">
              <h2>{t("PAGES_TITLE.PURCHASE_CLOSE")}</h2>
            </Grid>

            <Grid item container className="btn_box">
              <Grid item md={6} marginTop={2} className="btn_content">
                <MainButton
                  onClick={() => setWarningModal(false)}
                  title="Yo'q, ortga"
                  className="qolish_btn"
                />
              </Grid>
              <Grid item md={6} marginTop={2} className="btn_content">
                <MainButton
                  // @ts-ignore
                  onClick={() => {
                    setWarningModal(false);
                    navigate(backUrl);
                  }}
                  loader={loading && true}
                  disabled={loading}
                  title="Ha, bekor qilish"
                  className="bekor_btn"
                />
              </Grid>
            </Grid>
          </Grid>
        </PurchaseStyledModal>
      </Modal>
    </StepFormStyled>
  );
};

export default StepForm;
