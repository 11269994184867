import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid } from "@mui/material";
import { MainButton, SearchInput } from "components";
import { useApi } from "hooks/useApi/useApiHooks";
import React, { useEffect, useState } from "react";
import { TSetState } from "types/form.types";
import { TransactionFilterStyle } from "./tableHeader.style";
import { useLocation } from "react-router-dom";
import { cyrillicLetters, cyrillicToLatin } from "../Table.constants";

const TableHeader: React.FC<{
  searchable?: boolean;
  setSearch: TSetState<string | undefined>;
  search: string | undefined;
  title?: React.ReactNode | string;
  headerChildren: React.ReactNode;
  headerChildrenSecondRow: React.ReactNode;
  deletable: boolean;

  selectedRows: any[];
  onDelete: () => void;
  onAddButton?: () => void;
  addButtonTitle?: string;
  dataUrl?: string;
  setSearchValue?: any;
}> = ({
  onAddButton,
  onDelete,

  setSearch,
  searchable,
  search,
  headerChildren,
  headerChildrenSecondRow,
  deletable,
  selectedRows,
  title,
  addButtonTitle,
  dataUrl,
  setSearchValue,
}) => {
  const location = useLocation();

  return (
    <div>
      <header
        style={{ height: location.pathname.includes("cashbox") ? 0 : "45px" }}
      >
        <h2 className="py-2">{title} </h2>
      </header>

      <div className="d-flex justify-content-end align-items-center mt-1 mb-3 ">
        {searchable && (
          <Grid container className="search-box-container">
            <Grid item md={5}>
              <SearchInput
                value={search}
                onChange={(e: any) => {
                  // if (
                  //   cyrillicLetters.includes(e.target.value[0].toLowerCase())
                  // ) {
                  //   return setSearch(cyrillicToLatin(e.target.value));
                  // }
                  setSearch(e?.target?.value);
                  setSearchValue(e?.target?.value);
                }}
              />
            </Grid>
          </Grid>
        )}
        {/* <div className="table-header-right">
          {searchable ? (
            <SearchInput
              value={search}
              onChange={(e: any) => setSearch(e?.target?.value)}
            />
          ) : (
            <div></div>
          )}
        </div> */}
        <div className="actions d-flex align-items-center">
          {headerChildren}
          {onAddButton ? (
            <MainButton
              title={addButtonTitle || "Qo'shish"}
              variant="contained"
              onClick={() => {
                onAddButton?.();
              }}
            />
          ) : null}
          {deletable && selectedRows?.length > 0 && (
            <Button
              style={{ borderRadius: 12 }}
              onClick={onDelete}
              sx={{ width: 40 }}
              variant="outlined"
              color="error"
              // disabled={selectedRows.length <= 0 ? true : false}
              className="table_add_button"
            >
              <DeleteIcon />
            </Button>
          )}
        </div>
      </div>
      <div>{headerChildrenSecondRow}</div>
    </div>
  );
};

export default TableHeader;
