import styled from "styled-components";

interface ITextInputStyled {
  isError?: boolean;
}

export const TextInputStyled = styled.div<ITextInputStyled>`
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #32324d;
    padding: 5px 0;
    span {
      color: red;
    }
  }

  input {
    height: 48px;
    padding: 2px 12px !important;
    /* border-radius: 12px; */
    border: none;
    font-size: 16px;
    font-weight: 500;
    border-radius: 12px;
    background-color: #f5f5f5;
    border: 2px solid #f5f5f5;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #8e8ea9;
    }

    &:focus {
      border: 2px solid #0f6fdf;
    }
  }
  h6 {
    position: absolute;
    bottom: 0px;
  }
  .MuiFormControl-root {
    min-height: 46px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    border-radius: 12px;
    background-color: #f5f5f5;
  }
`;
