import styled from "styled-components";

export const ImageInputStyled = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #eaeaef;
  padding: 10px 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h3 {
    font-size: 14px;
    font-weight: 500;
    color: #32324d;
  }

  .image-main {
    text-align: center;
    padding: 10px;
    border-radius: 4px;
  }

  .image-bottom {
    margin-top: 18px;
    font-size: 14px;
    font-size: 600;
    color: #8e8ea9;
  }

  .image-label {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #0086ff;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.4s ease;
    &:hover {
      opacity: 0.8;
    }
  }

  .just-image,
  .pre-loader {
    width: 100%;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: contain !important;
    }
  }
  .input-file-row {
    display: inline-block;
  }
  .input-file {
    position: relative;
    display: inline-block;
    text-align: center;
  }
  .input-file span {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 14px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 4px;
    background-color: #419152;
    line-height: 22px;
    height: 40px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: background-color 0.2s;
  }
  .input-file input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
  }

  /* Focus */
  .input-file input[type="file"]:focus + span {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  /* Hover/active */
  .input-file:hover span {
    background-color: #59be6e;
  }
  .input-file:active span {
    background-color: #2e703a;
  }

  /* Disabled */
  .input-file input[type="file"]:disabled + span {
    background-color: #eee;
  }

  /* Список c превью */
  .input-file-list {
    padding: 10px 0;
  }
  .input-file-list-item {
    display: inline-block;
    margin: 0 15px 15px;
    width: 150px;
    vertical-align: top;
    position: relative;
  }
  .input-file-list-item img {
    width: 150px;
  }
  .input-file-list-name {
    text-align: center;
    display: block;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .input-file-list-remove {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 5px;
    right: 5px;
    background: #ff0202;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    border-radius: 50%;
  }
`;
